import { useState, useCallback, useEffect } from 'react'
import { Book } from './Book'
import { RangeInput } from './RangeInput'
import cover from '../assets/images/book.jpg'
import React, { useRef } from 'react'
import { Header } from './Header'
import axios from 'axios'
import { makeUseAxios } from 'axios-hooks'
import patreon from '../assets/images/patreon.jpeg'
import robin from '../assets/images/robin-icon.png'

export const demoValues = {
  imageUrl: cover as string,
  imageAlt: 'Popoye',
  href: 'http://robingood.com/',
}

export const defaultValues = {
  imageUrl: '',
  imageAlt: '',
  href: '',
  rotate: 30,
  rotateHover: 0,
  perspective: 600,
  transitionDuration: 1,
  radius: 2,
  thickness: 55,
  bgColor: '#01060f',
  width: 250,
  height: 375,
  pagesOffset: 3,
}

export const Controls = () => {
  const PATREON_LINK = 'http://patreon.com/Robin_Good'

  const useAxios = makeUseAxios({
    axios: axios.create({
      baseURL: 'https://ebookbackend.robingood.com/screenshot',
    }),
  })

  const [
    { data: putData, loading: putLoading, error: putError },
    executePost,
  ] = useAxios(
    {
      url: 'https://ebookbackend.robingood.com/screenshot',
      method: 'POST',
    },
    { manual: true }
  )

  const [queryDict, setQueryDict] = useState('')
  const [imageUrl, setImageUrl] = useState(defaultValues.imageUrl)
  const [imageAlt, setImageAlt] = useState(defaultValues.imageAlt)
  const [href, setHref] = useState(defaultValues.href)
  const [bgColor, setBgColor] = useState(defaultValues.bgColor)
  const [rotate, setRotate] = useState(defaultValues.rotate)
  const [rotateHover, setRotateHover] = useState(defaultValues.rotateHover)
  const [perspective, setPerspective] = useState(defaultValues.perspective)
  const [transitionDuration, setTransitionDuration] = useState(
    defaultValues.transitionDuration
  )
  const [radius, setRadius] = useState(defaultValues.radius)
  const [thickness, setThickness] = useState(defaultValues.thickness)
  const [width, setWidth] = useState(defaultValues.width)
  const [height, setHeight] = useState(defaultValues.height)
  const [pagesOffset, setPagesOffset] = useState(defaultValues.pagesOffset)

  const [fileSelected, setFileSelected] = useState(true)
  const [mode, setMode] = useState(true)
  const [isActive, setIsActive] = useState(false)
  const [hide, setHide] = useState(false)

  useEffect(() => {
    let queryDictVal = {}
    location.search
      .substr(1)
      .split('&')
      .forEach(function (item) {
        queryDictVal[item.split('=')[0]] = item.split('=')[1]
      })

    setQueryDict(queryDictVal)
  }, [])

  useEffect(() => {
    if (queryDict && queryDict.hasOwnProperty('id')) {
      console.log(queryDict)
      axios
        .get(`https://ebookbackend.robingood.com/get?id=${queryDict.id}`)
        .then((res) => {
          const { data } = res
          setImageUrl(data.imageUrl)
          setBgColor(data.bgColor)
          setRotate(data.rotate)
          setPerspective(data.perspective)
          setRadius(data.radius)
          setThickness(data.thickness)
          setWidth(data.width)
          setHeight(data.height)
          setPagesOffset(data.pagesOffset)
          setHide(true)

          const btn = document.createElement('BUTTON')
          btn.id = 'ready'
          document.getElementById('app').appendChild(btn)
        })
    }
    return () => {}
  }, [queryDict])

  const reset = useCallback(() => {
    setImageUrl(defaultValues.imageUrl)
    setImageAlt(defaultValues.imageAlt)
    setHref(defaultValues.href)
    setBgColor(defaultValues.bgColor)
    setRotate(defaultValues.rotate)
    setRotateHover(defaultValues.rotateHover)
    setPerspective(defaultValues.perspective)
    setTransitionDuration(defaultValues.transitionDuration)
    setRadius(defaultValues.radius)
    setThickness(defaultValues.thickness)
    setWidth(defaultValues.width)
    setHeight(defaultValues.height)
    setPagesOffset(defaultValues.pagesOffset)
  }, [])

  const fileRef = useRef(null)

  if (hide) {
    console.log('!')
    return (
      <Book
        imageUrl={imageUrl || demoValues.imageUrl}
        imageAlt={imageAlt || demoValues.imageAlt}
        href={href || demoValues.href}
        bgColor={bgColor}
        rotate={-rotate}
        rotateHover={-rotateHover}
        perspective={perspective}
        transitionDuration={transitionDuration}
        radius={radius}
        thickness={thickness}
        width={width}
        height={height}
        pagesOffset={pagesOffset}
      />
    )
  } else {
    return (
      <div>
        <Header></Header>
        {isActive && (
          <div
            style={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              zIndex: 99,
              opacity: '0.8',
              background: 'black',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <span
              style={{
                color: 'white',
                fontSize: 'xx-large',
                alignSelf: 'center',
              }}
            >
              <div>
                <div className="spinner-box">
                  <div className="circle-border">
                    <div className="circle-core"></div>
                  </div>
                </div>
              </div>
              Preparing your cover image....
            </span>
          </div>
        )}

        <div className="mx-2 mb-2">
          <div className="flex flex-wrap content-box bg-white p-1 rounded-sm lg:mx-48">
            <div className="flex-1 mx-2 right-side relative">
              {' '}
              <Book
                imageUrl={imageUrl || demoValues.imageUrl}
                imageAlt={imageAlt || demoValues.imageAlt}
                href={href || demoValues.href}
                bgColor={bgColor}
                rotate={-rotate}
                rotateHover={-rotateHover}
                perspective={perspective}
                transitionDuration={transitionDuration}
                radius={radius}
                thickness={thickness}
                width={width}
                height={height}
                pagesOffset={pagesOffset}
              />
              <div className="bg-transparent flex justify-center align-middle overflow-hidden absolute bottom-0 w-full">
                <div className=" py-5  sm:px-6">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    <button
                      className="float-right mx-2 bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center"
                      onClick={() => {
                        setIsActive(true)
                        const image = imageUrl
                        const options = {
                          imageUrl,
                          bgColor,
                          rotate,
                          perspective,
                          radius,
                          thickness,
                          width,
                          height,
                          pagesOffset,
                        }
                        var data = new FormData()
                        const payload = {
                          ...options,
                          image,
                        }
                        console.log(payload)

                        data.append('data', JSON.stringify(payload))
                        console.log(data)

                        executePost({
                          data: {
                            ...payload,
                          },
                        }).then((res) => {
                          const imageData = res.data

                          var a = document.createElement('a') //Create <a>
                          a.href = 'data:image/png;base64,' + imageData //Image Base64 Goes here
                          a.download = 'RobinGood-ebookcover.png' //File name Here
                          a.click() //Downloaded file
                          setIsActive(false)
                        })
                      }}
                    >
                      <svg
                        className="fill-current w-4 h-4 mr-2"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                      </svg>

                      <span>Download</span>
                    </button>
                    <button
                      onClick={() => {
                        if (mode) {
                          fileRef.current.click()
                        } else {
                          //   setFileSelected(false)
                          reset()
                          setMode(true)
                        }
                      }}
                      className="float-right bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    >
                      <input
                        id="file-upload"
                        className="hidden"
                        ref={fileRef}
                        type="file"
                        accept="image/*"
                        onChange={(e) => {
                          console.log(fileRef)

                          var file = fileRef.current.files[0]
                          var reader = new FileReader()
                          var url = reader.readAsDataURL(file)

                          reader.onloadend = function (e) {
                            console.log(reader.result)
                            // setFileSelected(true)
                            setMode(false)
                            setImageUrl(reader.result)
                          }
                          console.log(url)
                        }}
                      />
                      {mode ? 'Choose Image' : 'Reset'}
                    </button>
                  </h3>
                  <p className="mt-1 max-w-2xl text-sm leading-5 text-gray-500"></p>
                </div>
              </div>
            </div>

            <div className="flex-1 left-side">
              <div className="bg-black shadow overflow-hidden sm:rounded-lg  left-side">
                <div>
                  <dl>
                    {fileSelected && (
                      <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-6">
                        <div className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                          <dt className="flex text-sm leading-5 font-medium text-gray-500">
                            <svg
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              className="dots-vertical w-6 h-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                              />
                            </svg>
                            <span className="mt-1 mx-2">Cover Height</span>
                          </dt>
                          <RangeInput
                            label="Height:"
                            unit="px"
                            min={75}
                            max={375}
                            value={height}
                            step={25}
                            onChange={(e) => setHeight(Number(e.target.value))}
                          />
                        </div>
                        <div className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                          <dt className="flex text-sm leading-5 font-medium text-gray-500">
                            <svg
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              className="dots-horizontal w-6 h-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"
                              />
                            </svg>
                            <span className="mt-1 mx-2">Cover Width</span>
                          </dt>
                          <RangeInput
                            label="Width:"
                            unit="px"
                            min={50}
                            max={275}
                            value={width}
                            step={25}
                            onChange={(e) => setWidth(Number(e.target.value))}
                          />
                        </div>

                        <div className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                          <dt className="flex text-sm leading-5 font-medium text-gray-500">
                            <svg
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              className="chevron-double-up w-6 h-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M5 11l7-7 7 7M5 19l7-7 7 7"
                              />
                            </svg>
                            <span className="mt-1 mx-2">Book Thickness</span>
                          </dt>
                          <RangeInput
                            label="Thickness:"
                            unit="px"
                            min={5}
                            max={100}
                            value={thickness}
                            step={5}
                            onChange={(e) =>
                              setThickness(Number(e.target.value))
                            }
                          />
                        </div>

                        <div className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                          <dt className="flex text-sm leading-5 font-medium text-gray-500">
                            <svg
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              className="refresh w-6 h-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                              />
                            </svg>
                            <span className="mt-1 mx-2">Rotation Angle</span>
                          </dt>
                          <RangeInput
                            label="Rotate:"
                            unit="deg"
                            min={0}
                            max={45}
                            value={rotate}
                            step={1}
                            onChange={(e) => setRotate(Number(e.target.value))}
                          />
                        </div>

                        <div className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                          <dt className="flex text-sm leading-5 font-medium text-gray-500">
                            <svg
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              className="collection w-6 h-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
                              />
                            </svg>
                            <span className="mt-1 mx-2">Perspective</span>
                          </dt>

                          <RangeInput
                            label="Perspective:"
                            unit="px"
                            min={300}
                            max={1300}
                            value={perspective}
                            step={100}
                            onChange={(e) =>
                              setPerspective(Number(e.target.value))
                            }
                          />
                        </div>

                        <div className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                          <dt className="flex text-sm leading-5 font-medium text-gray-500">
                            <svg
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              className="menu-alt4 w-6 h-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M4 8h16M4 16h16"
                              />
                            </svg>
                            <span className="mt-1 mx-2">
                              Cover Extra Border
                            </span>
                          </dt>
                          <RangeInput
                            label="Pages offset:"
                            unit="px"
                            min={0}
                            max={10}
                            value={pagesOffset}
                            step={1}
                            onChange={(e) =>
                              setPagesOffset(Number(e.target.value))
                            }
                          />
                        </div>
                        <div className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                          <dt className="flex text-sm leading-5 font-medium text-gray-500">
                            <svg
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              className="stop w-6 h-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                              />
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M9 10a1 1 0 011-1h4a1 1 0 011 1v4a1 1 0 01-1 1h-4a1 1 0 01-1-1v-4z"
                              />
                            </svg>
                            <span className="mt-1 mx-2">
                              Rounded / Square Corners
                            </span>
                          </dt>

                          <RangeInput
                            label="Radius:"
                            unit="px"
                            min={0}
                            max={5}
                            value={radius}
                            step={1}
                            onChange={(e) => setRadius(Number(e.target.value))}
                          />
                        </div>

                        <dt className="flex text-sm leading-5 font-medium text-gray-500">
                          <svg
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            className="color-swatch w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M7 21a4 4 0 01-4-4V5a2 2 0 012-2h4a2 2 0 012 2v12a4 4 0 01-4 4zm0 0h12a2 2 0 002-2v-4a2 2 0 00-2-2h-2.343M11 7.343l1.657-1.657a2 2 0 012.828 0l2.829 2.829a2 2 0 010 2.828l-8.486 8.485M7 17h.01"
                            />
                          </svg>
                          <span className="mt-1 mx-2">Back Cover Color</span>

                          <dd className="mt-1 flex text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                            {
                              //   <span className="text-gray-500  mt-1 mx-2">
                              //     Selected Color
                              //   </span>
                              //     <span
                              //   style={{
                              //     float: 'left',
                              //     width: '20px',
                              //     height: '20px',
                              //     margin: '5px',
                              //     border: '1px solid rgba(0, 0, 0, .2)',
                              //     backgroundColor: bgColor,
                              //   }}
                              // ></span>
                            }
                            <input
                              type="color"
                              value={bgColor}
                              onChange={(e) => setBgColor(e.target.value)}
                            />
                          </dd>
                        </dt>
                      </div>
                    )}
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
