import React from 'react'

export interface Props
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  label: string
  unit: string
}

export const RangeInput = ({ label, unit, ...inputProps }: Props) => {
  return (
    <label>
      <span className="inputs ">
        <input type="range" {...inputProps} />
        <input
          className="mx-2 bg-white focus:outline-none focus:shadow-outline text-white bg-black rounded-lg py-2 px-4 block w-full appearance-none leading-normal"
          type="number"
          {...inputProps}
        />
        <span className="unit invert text-gray-500">{unit}</span>
      </span>
    </label>
  )
}
