import React from 'react'
import robin from '../assets/images/robin-icon.png'
import logo from '../assets/logo.png'
import patreon from '../assets/images/patreon.jpeg'

export const Header = () => {
  const PATREON_LINK = 'http://patreon.com/Robin_Good'

  return (
    <header className="flex justify-between mt-12 mb-10 lg:mx-48">
      <div className="flex justify-center align-middle">
        <img className="h-20 w-20" src={logo} alt="Workflow logo" />
        <div className="title my-3 text-4xl sm:text-3xl text-white">
          eBook 3D Cover Generator
        </div>

        <div className="credit flex justify-end align-middle ">
          <h6
            className=""
            style={{
              color: 'white',
              fontWeight: 'normal',
            }}
          >
            by{' '}
            <span className="text-white">
              {' '}
              <a
                href="https://robingood.com"
                target="_BLANK"
                style={{ color: 'white !important' }}
              >
                {' '}
                Robin Good{' '}
              </a>
              <img
                src={robin}
                style={{
                  width: '36px',
                  height: '36px',
                  display: 'inline',
                }}
              />
            </span>
          </h6>
        </div>
      </div>
      <div className="flex justify-end self-end align-middle text-white">
        <span className="align-middle center-text">
          Made with ❤️ - Please support me on
        </span>
        <a href={PATREON_LINK} target="_BLANK">
          <img
            src={patreon}
            className="pateron-img"
            style={{ display: 'inline', width: '145px' }}
          />
        </a>
      </div>
    </header>
  )
}
